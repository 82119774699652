<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-body">
          <slot name="body">
            <div class="modal-name">Editing user: <span class="text-success">{{ editPortalUser.name }}</span></div>
            <div class="form-group pt-2">
              <label for="PortalUserName">User Name</label>
              <input
                v-model="editedPortalUserName"
                type="text"
                class="form-control"
              >
            </div>
            <div class="font-weight-bold">Users role</div>
            <div class="form-check">
              <input
                id="Member"
                v-model="editedRoleId"
                class="form-check-input"
                type="radio"
                value="0"
              >
              <label for="Member">Member</label>
            </div>
            <div class="form-check">
              <input
                id="Admin"
                v-model="editedRoleId"
                class="form-check-input"
                type="radio"
                value="1"
              >
              <label for="Admin">Admin</label>
            </div>
            <div class="font-weight-bold">Assigned to Companies</div>
            <div class="form-check">
              <div v-for="company in allCompanies" :key="company">
                <input
                  v-model="selectedCompanies"
                  class="form-check-input"
                  type="checkbox"
                  name="selectedCompanies"
                  :value="company.id"
                >
                <label for="selectedCompanies" class="pr-1"> {{ company.companyName }} </label>
              </div>
            </div>
            <div style="height: 20px;">
              <p v-show="errors" class="text-danger"> {{ errors }}</p>
              <p v-show="success" class="text-success"> {{ success }}</p>
            </div>
          </slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <button
              type="button"
              class="btn btn-danger"
              @click="clear(); this.$emit('close');"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="editedPortalUser()"
            >
              Save User
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminEditPortalUser',
  props: {
    editPortalUser: {
      type: Object,
      required: true
    },
    allCompanies: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  data () {
    return {
      editedPortalUserName: '',
      editedRoleId: '',
      selectedCompanies: [],
      errors: '',
      success: '',
      validCustomer: false,
    }
  },
  mounted () {
    this.editedPortalUserName = this.editPortalUser.name
    this.editedRoleId = this.editPortalUser.roleId
    this.selectedCompanies = this.editPortalUser.companyId
  },
  methods: {
    async editedPortalUser () {
      const payload = {
        portalUserId: this.editPortalUser.id,
        userName: this.editedPortalUserName,
        roleId: this.editedRoleId,
        companyId: this.selectedCompanies
      }
      await this.$store.dispatch('editPortalUser', payload)
        .then(response => {
          if(response){
            this.success = response.data
            this.$store.dispatch('getPortalUsers')
          }
        })
        .catch (error => {
          this.errors = error.response.data
        }) 
    },
    clear () {
      this.errors = ''
      this.success = ''
    }
  }
}
</script>
