<template>
  <div class="h2 mb-4 text-secondary">Portal Users</div>
  {{ errors }}
  <table class="table table-sm table-striped table-borderless">
    <thead>
      <tr>
        <th
          scope="col"
          style="width: 5%"
          class="text-center"
        >
          #
        </th>
        <th scope="col" style="width: 20%">Portal User Name</th>
        <th scope="col" style="width: 20%">Portal User Role</th>
        <th scope="col" style="width: 25%">Assigned to Companies</th>
        <th
          scope="col"
          style="width: 35%"
          class="text-right"
        />
      </tr>
    </thead>
    <tbody>
      <tr v-for="portalUser in allPortalUsers" :key="portalUser">
        <td scope="row" class="text-center">{{ portalUser.id }}</td>
        <td>{{ portalUser.name }}</td>
        <td>{{ portalUser.roleId === 1 ? 'Admin' : 'Member' }}</td>
        <td>{{ findCompanyName(portalUser.companyId) }}</td>
        <td class="text-right">
          <pencil-outline class="icons text-secondary" @click="isEditPortalUserVisible = !isEditPortalUserVisible; editPortalUser = portalUser;" />
          <lock-outline class="icons text-success" @click="isPortalUserChangePasswordVisible = !isPortalUserChangePasswordVisible; editPortalUser = portalUser;" />
          <trash-can-outline class="icons text-danger" @click="deletePortalUser(portalUser.id)" />
        </td>
        <transition name="animation">
          <adminEditPortalUserModal
            v-if="isEditPortalUserVisible"
            :edit-portal-user="editPortalUser"
            :all-companies="allCompanies"
            @close="isEditPortalUserVisible = !isEditPortalUserVisible"
          />
        </transition>
        <transition name="animation">
          <adminChangePasswordPortalUser
            v-if="isPortalUserChangePasswordVisible"
            :edit-portal-user="editPortalUser"
            @close="isPortalUserChangePasswordVisible = !isPortalUserChangePasswordVisible"
          />
        </transition>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { LockOutline, PencilOutline, TrashCanOutline } from 'mdue'
import { mapState, mapActions } from 'vuex'
import adminEditPortalUserModal from './adminEditPortalUserModal'
import adminChangePasswordPortalUser from './adminChangePasswordPortalUser'

export default {
  components: {
    PencilOutline,
    TrashCanOutline,
    LockOutline,
    adminEditPortalUserModal,
    adminChangePasswordPortalUser,
  },
  data () {
    return {
      isEditPortalUserVisible: false,
      isPortalUserChangePasswordVisible: false,
      editPortalUser: '',
    }
  },
  computed: {
    ...mapState([
      'allPortalUsers',
      'allCompanies'
    ])
  },
  created () {
    this.getAllCompanies()
    this.getPortalUsers()
  },
  methods: {
    ...mapActions([
      'getAllCompanies',
      'getPortalUsers'
    ]),

    findCompanyName (arrOfCompanyIds) {
      if (!this.allCompanies.length) { return [] }
      // if has companies return the names      const companyNames = data.map(item => item.companyName )
      const data = this.allCompanies.filter(item => arrOfCompanyIds.includes(item.id))
      const companyNames = data.map(item => item.companyName ).join(', ')
      return companyNames
    },
    deletePortalUser (portalUserId) {
      if(portalUserId === 1) {
        confirm('portal user admin cannot be deleted')
      } else {
        if(confirm('are you sure you want to delete this Portal User?'))
        {this.$store.dispatch('deletePortalUser', portalUserId)
          .then(() => this.$store.dispatch('getPortalUsers'))}
      }
    }
  }
}
</script>