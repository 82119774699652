<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-body">
          <slot name="body">
            <div class="modal-name">Changing password for user: <span class="text-success">{{ editPortalUser.name }}</span></div>
            <div class="form-group pt-2">
              <label for="newPassword">New Password</label>
              <input
                v-model="password"
                type="password"
                class="form-control"
              >
            </div>
            <div class="form-group">
              <label for="newPassword2">Repeat Password</label>
              <input
                v-model="password2"
                type="password"
                class="form-control"
              >
            </div>
            <div style="height: 20px;">
              <p v-show="errors" class="text-danger"> {{ errors }}</p>
              <p v-show="success" class="text-success"> {{ success }}</p>
            </div>
          </slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <button
              type="button"
              class="btn btn-danger"
              @click="clear(); this.$emit('close');"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="changePortalUserPassword()"
            >
              Save User
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangePasswordPortalUser',
  props: {
    editPortalUser: {
      type: Object,
      required: true
    },
  },
  emits: ['close'],
  data () {
    return {
      password: '',
      password2: '',
      errors: '',
      success: '',
    }
  },
  methods: {
    async changePortalUserPassword () {
      if (this.password === this.password2) {
        const payload = {
          portalUserId: this.editPortalUser.id,
          newPassword: this.password
        }
        await this.$store.dispatch('changePortalUserPassword', payload)
          .then(response => {
            if(response){
              this.success = response.data
            }
          })
          .catch (error => {
            this.errors = error.response.data
          })
      } else {
        this.errors = 'Passwords does not match'
      }
    },
    clear () {
      this.errors = ''
      this.success = ''
    }
  }
}
</script>
